<template>
  <div>
    <div class="mobileShieldBox">
      <img src="@/assets/image/productCenter/mobileShieldBanner.png" alt="手机盾">
      <h2>手机盾</h2>
      <p>手机盾是面向移动端场景的一款产品,提供可信的数字签名功能。协同签名系统采用密钥分割技术和协同数字签名技术,实现了密钥分量在移动签名组件和服务器端的独立生成和独立存储。签名过程中,移动端和服务端协作,共同完成完整签名。可适用于OA协同、电子政务、移动办公、金融支付、健康医疗、移动端身份认证等多种场景。</p>
    </div>
    <v-functional :list="functional" />
    <v-learnMore />
    <v-networkSecurity />
  </div>
</template>

<script>
import learnMore from './components/learnMore.vue';
import functionalItem from './components/functionalItem.vue';
import networkSecurity from '../../../components/networkSecurity.vue';
export default {
  data () {
    return {
      functional: [
        {
          icon: 'personCert1.png',
          title: '身份认证',
          intro:
            '手机盾服务提供基于PKI证书和电子签名的身份认证方式，认证强度远高于口令和动态密码等认证手段，有效防止用户身份被伪造。'
        },
        {
          icon: 'mobileShield1.png',
          title: '证书与密钥管理',
          intro:
            '手机盾服务提供密钥的生成、安全存储、更新、销毁，以及证书的申请、签发、更新、吊销等管理功能，对用户在移动终端上的核心数据进行安全保护，确保用户密钥数据安全。'
        },
        {
          icon: 'timestamp5.png',
          title: '密码算法支持',
          intro:
            '手机盾服务全面支持国产算法以及部分国际通用密码算法，满足用户各种加密的算法需求。'
        },
        {
          icon: 'mobileShield2.png',
          title: '低成本',
          intro:
            '手机盾服务不依赖任何额外的硬件密码模块，相比传统U盾等硬件设备成本更低，用户体验更好。'
        }
      ],
      features: [
        {
          title: '免部署',
          intro: '免部署即享可信时间戳云服务，安全可信，节约成本。'
        },
        {
          title: '权威国家时间源',
          intro:
            '豸信CA建立的可信时间戳云服务平台依托国家授时中心的可信时间源，合法合规，有法律保障。'
        }
      ]
    }
  },
  components: {
    'v-learnMore': learnMore,
    'v-functional': functionalItem,
    'v-networkSecurity': networkSecurity,
  },
}
</script>

<style lang="scss" scoped>
.mobileShieldBox {
  width: 7.5rem;
  height: 9.8rem;
  background: #f0f4ff;
  padding: 0.73rem 0.53rem 0.24rem;
  box-sizing: border-box;
  text-align: center;
  img {
    width: 5rem;
  }
  h2 {
    font-size: 0.48rem;
    color: #303b50;
    line-height: 0.67rem;
    margin: 0.26rem 0;
  }
  p {
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.44rem;
  }
}
</style>